<template>
    <div class="reserve" v-loading="loading">
        <div class="reserve-center">
            <!-- 凯迪拉克中心 -->
            <div class="reserve-batten">
                <div class="batten-left"><img :src="thumbnail" alt=""></div>
                <div class="batten-right">
                    <div class="batten-title">
                        <h2>{{venueList.name}}</h2>
                        <div>
                            <p>热线电话：</p><span style="margin-right:15px;">{{venueList.mobilePhone}}</span><span>{{venueList.telPhone}}</span>
                        </div>
                    </div>
                    <div class="batten-address">
                        <div>
                            <span class="grand-hall" v-if="venueList.type == 0">#小馆#</span>
                            <span class="grand-hall" v-if="venueList.type == 1">#大馆#</span>
                            <span class="grand-hall" v-if="venueList.type == 2">#体育场#</span>
                            <span class="grand-hall" v-if="venueList.type == 3">#篮球馆#</span>
                            <p>{{venueList.address}}</p>
                        </div>
                        <!-- <h3>活动运营指南</h3> -->
                    </div>
                    <div class="batten-map">
                        <Map v-if="map" ref="map" :mapList="mapList"></Map>
                    </div>
                </div>
            </div>
            <!-- 活动基本概况 -->
            <div class="reserve-survey">
                <p class="reserve-title">活动基本概况</p>
                <div class="survey-center">
                    <div class="survey-list-cen survey-list-left">
                        <div class="survey-list">
                            <p><i>*</i>活动名称</p>
                            <el-input v-model="form.name" auto-complete="off" placeholder="请输入活动名称" clearable></el-input>
                        </div>
                        <div class="survey-list">
                            <p>活动英文名称</p>
                            <el-input v-model="form.ename" auto-complete="off" placeholder="请输入活动英文名称" clearable></el-input>
                        </div>
                        <div class="survey-list">
                            <p><i>*</i>活动场次</p>
                            <el-input v-model="form.num" auto-complete="off" placeholder="请输入活动场次" clearable></el-input>
                        </div>
                        <div class="survey-list">
                            <p style="display:flex;align-items:center;"><i>*</i>预订日期 <span class="list-time">（请在结束日期次日06:00前清场）</span></p>
                            <el-date-picker v-model="datePickerValue" format="yyyy.MM.dd HH:mm" value-format="yyyy.MM.dd HH:mm" :default-time="['08:00:00', '23:59:00']" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @change="onDate" size="mini">
                            </el-date-picker>

                        </div>
                        <div class="survey-list">
                            <p style="display:flex;align-items:center;"><i>*</i>活动日期</p>
                            <div class="survey-list-icon">
                                <el-date-picker v-model="activituyTime" format="yyyy.MM.dd HH:mm" value-format="yyyy.MM.dd HH:mm" :default-time="['09:00:00', '22:00:00']" type="datetimerange" range-separator="添加" @change="onDateActiv" size="mini" :picker-options="pickerOptions1"></el-date-picker>
                                <div class="survey-list-date">
                                    <div v-for="(item,index) in form.activityTimeList" :key="index">
                                        <p>{{item.activityStart}} 至 {{item.activityEnd}}</p><i @click="onDelete(index)">x</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="survey-list-cen survey-list-right">
                        <div class="survey-list">
                            <p><i>*</i>三面/四面台</p>
                            <el-select v-model="form.stageType" placeholder="请选择" clearable @change="onSided">
                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div class="survey-list">
                            <div class="survey-list-radio">
                                <p><i>*</i>售票方式</p>
                                <el-radio-group v-model="form.ticketType" @change="onRadio">
                                    <el-radio :label="0" size="mini" text-color="#123E90">公开售票</el-radio>
                                    <el-radio :label="1" size="mini" text-color="#123E90">非公开售票</el-radio>
                                </el-radio-group>
                            </div>
                            <!-- <el-input v-model="form.ticketNum" auto-complete="off" placeholder="请输入售票数量"></el-input> -->
                        </div>
                        <div class="survey-list">
                            <p><i>*</i>预估人数</p>
                            <el-input v-model="form.viewerNum" auto-complete="off" placeholder="请输入预估人数" clearable></el-input>
                        </div>
                        <div class="survey-list">
                            <p>备注</p>
                            <el-input type="textarea" placeholder="请输入其他备注需求限制150字" v-model="form.remark" maxlength="150" show-word-limit></el-input>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 活动信息 -->
            <div class="reserve-survey reserve-information">
                <p class="reserve-title">活动信息</p>
                <div class="survey-center">
                    <div class="survey-list-cen survey-list-left">
                        <div class="survey-list">
                            <p><i>*</i>活动类型</p>
                            <el-select v-model="activity" @change='onActivity' placeholder="活动类型" size="mini" class="m-r-12">
                                <el-option v-for="item in activityTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </div>
                        <div class="survey-list" v-for="(item,index) in fieldList" :key="index">
                            <p><i>*</i>{{item.fieldName}}</p>
                            <el-input v-model="item.value" auto-complete="off" placeholder="请输入" clearable></el-input>
                        </div>
                    </div>
                    <div class="survey-list-cen survey-list-right">
                        <div class="survey-list">
                            <div class="survey-list-radio">
                                <p><i>*</i>是否取消过演出或活动</p>
                                <el-radio-group v-model="form.canceled" @change="onCanceled">
                                    <el-radio :label="1" size="mini" text-color="#123E90">取消过</el-radio>
                                    <el-radio :label="0" size="mini" text-color="#123E90">未取消过</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="survey-list">
                            <p><i v-if="form.canceled == 1">*</i>原因说明</p>
                            <el-input type="textarea" placeholder="请输入" v-model="form.cancelReason" maxlength="500" show-word-limit clearable></el-input>
                        </div>
                        <div class="survey-list">
                            <p><i v-if="form.canceled == 1">*</i>意见/建议</p>
                            <el-input type="textarea" placeholder="请输入" v-model="form.suggest" maxlength="200" show-word-limit clearable></el-input>
                        </div>
                        <div class="survey-list">
                            <p>确认函</p>
                            <div class="upload-end">
                                <el-upload class="upload-demo" ref="upload" drag accept=".pdf, .jpg, .png" multiple action="/" :auto-upload="true" :http-request='submitUpload'>
                                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                    <div v-else class="avater-tips">
                                        <img src="../../assets/img/uoload.png" alt="">
                                        <p>请上传</p>
                                    </div>
                                </el-upload>
                                <p class="upload-end-submit">提示：<span>支持jpg、png、pdf格式</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 主办方信息 -->
            <div class="reserve-survey sponsor">
                <p class="reserve-title">主办方信息</p>
                <div class="survey-center">
                    <div class="survey-list-cen survey-list-left">
                        <div class="survey-list">
                            <p>主办方名称</p>
                            <el-input v-model="sponsor.name" auto-complete="off" placeholder="请输入主办方名称" :disabled="true" clearable></el-input>
                        </div>
                        <div class="survey-list">
                            <p>信用代码</p>
                            <el-input v-model="sponsor.creditCode" auto-complete="off" placeholder="请输入信用代码" :disabled="true" clearable></el-input>
                        </div>
                        <div class="survey-list">
                            <p><i>*</i>负责人</p>
                            <el-input v-model="form.sponsorName" auto-complete="off" placeholder="请输入负责人" clearable></el-input>
                        </div>
                        <div class="survey-list">
                            <p><i>*</i>手机号</p>
                            <el-input v-model="form.sponsorPhone" auto-complete="off" placeholder="请输入手机号" clearable></el-input>
                        </div>
                    </div>
                    <div class="survey-list-cen survey-list-right">
                        <div class="survey-list">
                            <p><i>*</i>邮箱</p>
                            <el-input v-model="form.sponsorMail" auto-complete="off" placeholder="请输入邮箱" clearable></el-input>
                        </div>
                        <div class="survey-list">
                            <p>所在地</p>
                            <div class="sponsor-center">
                                <el-input v-model="sponsor.provName" :disabled="true" style="width:32%;" clearable></el-input>
                                <el-input v-model="sponsor.cityName" :disabled="true" style="width:32%;" clearable></el-input>
                                <el-input v-model="sponsor.districtName" :disabled="true" style="width:32%;margin-right:0;" clearable></el-input>
                            </div>
                            <el-input v-model="sponsor.address" auto-complete="off" placeholder="请输入详细地址" :disabled="true" clearable></el-input>
                        </div>
                        <div class="survey-list">
                            <p>营业执照</p>
                            <!-- <div class="upload-end">
                                <img :src="sponsor.businessLicense" alt="">
                            </div> -->
                            <el-popover placement="top-start" trigger="click"> <!--trigger属性值：hover、click、focus 和 manual-->
                                <a :href="sponsor.businessLicense" target="_blank" title="查看最大化图片">
                                    <img :src="sponsor.businessLicense" style="width: 600px;height: 600px">
                                </a>
                                <img slot="reference" :src="sponsor.businessLicense" class="upload-enda">
                            </el-popover>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 承办方信息 -->
            <div class="reserve-survey undertaker">
                <p class="reserve-title">承办方信息</p>
                <div class="survey-center">
                    <div class="survey-list-cen survey-list-left">
                        <div class="survey-list">
                            <p>承办方名称</p>
                            <el-input v-model="form.undertakerName" auto-complete="off" placeholder="请输入承办方名称" clearable></el-input>
                        </div>
                    </div>
                    <div class="survey-list-cen survey-list-right">
                        <div class="survey-list">
                            <p>所在地</p>
                            <div class="sponsor-center">
                                <el-select v-model="form.undertakerProv" clearable placeholder="请选择省" value-key="form.undertakerProv" @change="onRegion">
                                    <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                                <el-select v-model="form.undertakerCity" clearable placeholder="请选择市" value-key="form.undertakerCity" @change="onCity">
                                    <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                                <el-select v-model="form.undertakerDistrict" clearable placeholder="请选择区" value-key="form.undertakerDistrict" style="margin-right:0;" @change="onDistrict">
                                    <el-option v-for="item in districtList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                            </div>
                            <el-input v-model="form.undertakerAddress" auto-complete="off" placeholder="请输入详细地址" clearable></el-input>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 确认提交 -->
            <div class="reserve-bottom">
                <button type="button" @click="onSubmit">确认提交</button>
            </div>
        </div>
    </div>
</template>

<script>
import Map from "../../components/Map";
import { listArea } from "../../api/address";
import { uploadConfirmation, uploadFile } from "../../api/upload";
import { checkStartTime,getVenueInfoById,checkActivityTime } from "../../api/venue"
import {
    listActivityType,
    saveBooking,
    listFieldByTypeId,
    getSponsor,
} from "../../api/venue";
export default {
    components: {
        Map,
    },
    data() {
        return {
            form: {
                name: "",
                ename: "",
                num: "",
                startTime: "",
                endTime: "",
                activityTimeList: [],
                stageType: 0,
                ticketType: 0,
                ticketNum: "",
                viewerNum: "",
                remark: "",
                sponsorName: "",
                sponsorPhone: "",
                sponsorMail: "",
                undertakerName: "",
                undertakerProv: "",
                undertakerCity: "",
                undertakerDistrict: "",
                undertakerAddress: "",
                activityTypeId: 1,
                venueId: "",
                list: [],
                companyId: "",
                canceled: 0,
                cancelReason: "",
                suggest: "",
            },
            map:false,
            datePickerValue: "",
            active_name: "",
            value: "",
            options: [{
                    value: 0,
                    label: "三面台",
                },
                {
                    value: 1,
                    label: "四面台",
                },
                {
                    value: 2,
                    label: "其他",
                },
            ],
            activity: 1,
            // activityStart:"",
            // activityEnd:"",
            imageUrl: "",
            address: "",
            venueInfo: {},
            areaList: [],
            cityList: [],
            districtList: [],
            pid: 100000,
            level: 1,
            type: "",
            activityTypeList: [],
            fieldList: [],
            sponsor: {},
            list: [],
            activituyTime: "",
            code: "",
            upType: 0,
            startData: null,
            endData: null,
            //预订日期 禁选当前日以前时间
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
            //活动日期 禁选当前日以前时间
            // pickerActive:{
            //     disabledDate(time) {
            //         return time.getTime() < Date.now() - 8.64e7;
            //     }
            // },
            pickerOptions1: {
                onPick: () => {
                   
                },
                disabledDate: (time) => {
                    const day = 24 * 3600 * 1000;
                    let maxDate = this.startData - day;
                    let minDate = this.endData ;
                    return time.getTime() > minDate || time.getTime() < maxDate;
                },
            },
            mapList:[],
            loading:false,
            reserve_id:"",
            venueList:{},
            venue_id:"",
            active_value:"",
            thumbnail:""

        };
    },
    created() {
        // console.log('场馆id',this.$route.params.venueInfo.id);
        this.form.venueId = this.$route.query.reserve_id;
        this.venueInfo = this.$route.query.venueInfo;
        this.type = this.$route.query.venueInfo.type;
        this.reserve_id = this.$route.query.reserve_id;
        console.log(this.reserve_id)
        // console.log(this.mapList)
        this.listActivityType();
        this.level = 1;
        this.listArea(true);
        this.listFieldByTypeId();
        this.getSponsor();
        this.getVenueInfoById()

    },
    methods: {
        // pickerOptions1(){
        //         disabledDate(time) {
        //             return time.getTime() < Date.now() - 8.64e7;
        //         }
        //     },

        //获取信息
        getVenueInfoById() {
            getVenueInfoById(this.reserve_id).then((res) => {
                if (res.data.code == 20000) {
                    this.venueList = res.data.data.venueInfo;
                    this.thumbnail = res.data.data.venueInfo.thumbnail
                    console.log(this.thumbnail)
                    this.mapList.push(this.venueList.lng)
                    this.mapList.push(this.venueList.lat)
                    console.log(this.mapList)
                    this.map = true
                    this.venue_id = res.data.data.venueInfo.id
                    // this.$store.commit('setmapList',this.mapList)
                }
            });
        },

        //活动类型下拉框
        listActivityType() {
            listActivityType({
                type: this.type,
            }).then((res) => {
                if (res.data.code == 20000) {
                    this.activityTypeList = res.data.data.activityTypeList;
                }
            });
        },

        //活动类型
        onActivity(value) {
            // console.log('活动类型',value)
            this.activity = value;
            this.form.activityTypeId = value;
            this.listFieldByTypeId();
        },

        //根据类型id获取字段
        listFieldByTypeId() {
            listFieldByTypeId({
                id: this.activity,
            }).then((res) => {
                if (res.data.code == 20000) {
                    this.fieldList = res.data.data.fieldList.map((item) => {
                        item.value = "";
                        return item;
                    });
                } else if (res.data.code == 20005) {
                    this.$message.error(res.data.message);
                    this.$router.push("/login");
                }
            });
        },

        //主办方信息
        getSponsor() {
            getSponsor().then((res) => {
                if (res.data.code == 20000) {
                    this.sponsor = res.data.data.sponsor;
                    this.form.companyId = res.data.data.sponsor.id;
                }
            });
        },
        formDateShow(value) {
            var date = new Date(value);
            var time = Date.parse(date);
            return time;
        },
        //预订日期
        onDate(val) {
            console.log(this.datePickerValue)
            this.form.startTime = this.datePickerValue[0];
            this.form.endTime = this.datePickerValue[1];
            this.startData = this.formDateShow(
                this.datePickerValue[0].split(" ")
            );
            this.endData = this.formDateShow(
                this.datePickerValue[1].split(" ")
            );
            console.log(this.startData);
            console.log(this.endData);
            this.checkStartTime()
        },

        //选择预约时间
        checkStartTime(){
            checkStartTime({
                id:this.venue_id,
                code:"",
                startTime:this.form.startTime,
                endTime:this.form.endTime
            }).then((res) => {
                if(res.data.code == 20000){
                    this.$message.success(res.data.message)
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },

        //活动日期
        onDateActiv() {
            // console.log(this.activituyTime)
            this.form.activityTimeList.push({
                activityStart: this.activituyTime[0],
                activityEnd: this.activituyTime[1],
            });
            console.log(this.form.activityTimeList)
            // checkActivityTime({
            //     startTime:this.form.startTime,
            //     endTime:this.form.endTime,
            //     activityStart:this.activituyTime[0],
            //     activityEnd:this.activituyTime[1]
            // }).then((res) => {
            //     if(res.data.code == 20000){
            //         this.$message.success(res.data.message)
            //     }
            // })
        },

        //选择台
        onSided(value) {
            console.log(value)
            this.form.stageType = value;
        },

        //是否取消过
        onCanceled(value) {
            // console.log(value)
            this.form.canceled = value;
        },

        //售票方式
        onRadio(value) {
            // console.log(value)
            this.form.ticketType = value;
        },

        onDelete(index) {
            this.form.activityTimeList.splice(index, 1);
        },

        onRegion(value) {
            // console.log('省',value)
            this.form.undertakerProv = value;
            this.level = 2;
            this.pid = value;
            this.listArea();
        },

        onCity(value) {
            // console.log('市',value)
            this.form.undertakerCity = value;
            this.level = 3;
            this.pid = value;
            this.listArea();
        },

        onDistrict(value) {
            // console.log('区',value)
            this.form.undertakerDistrict = value;
        },

        //地区
        listArea(type) {
            listArea({
                pid: this.pid,
            }).then((res) => {
                if (res.data.code == 20000) {
                    // console.log(this.level, res.data.data.areaList)
                    if (this.level == 1) {
                        this.areaList = res.data.data.areaList;
                        if (type) {
                            this.level = 2;
                            this.pid = res.data.data.areaList[0].id;
                            this.listArea(type);
                        }
                    } else if (this.level == 2) {
                        this.cityList = res.data.data.areaList;
                        if (type) {
                            this.level = 3;
                            this.pid = res.data.data.areaList[0].id;
                            this.listArea(type);
                        }
                    } else if (this.level == 3) {
                        this.districtList = res.data.data.areaList;
                    }
                }
            });
        },

        // 上传确认函
        async submitUpload({ file }) {
            this.loading = true
            console.log("上传确认函 >>> ");
            const isLt2M = file.size / 1024 / 1024 < (this.maxSize || 1);
            let formData = new FormData();
            formData.append("file", file, file.name);
            // formData.append('code', this.code)
            // formData.append('type', this.upType)


            let res = await uploadFile(formData);
            this.upload_url = res.data.data.url;
            if (res.data.code == 20000) {
                 this.loading = false
                this.$emit("selectImg", { url: res.data.data.url });
                this.imageUrl = res.data.data.url;
                this.form.url = res.data.data.url;
            }
        },
        
        

        //确认提交
        onSubmit() {
            if(this.form.name == ""){
                this.$message.error('活动名称不能为空')
                return false
            }else if(this.form.num == ""){
                this.$message.error('活动场次不能为空')
                return false
            }else if(this.datePickerValue == ""){
                this.$message.error('预订日期不能为空')
                return false
            }else if(this.activituyTime == ""){
                this.$message.error('请选择活动日期')
                return false
            }else
            //  if(this.form.stageType == ""){
            //     this.$message.error('舞台类型不能为空')
            //     return false
            // }else 
            if(this.form.viewerNum == ""){
                this.$message.error('预估人数不能为空')
                return false
            }else if(this.form.activity == ""){
                this.$message.error('活动类型不能为空')
                return false
            }else if(this.form.sponsorName == ""){
                this.$message.error('负责人不能为空')
                return false
            }else if(this.form.sponsorPhone == ""){
                this.$message.error('手机号不能为空')
                return false
            }else if(this.form.sponsorMail == ""){
                this.$message.error('邮箱不能为空')
                return false
            }

            this.fieldList.forEach(element => {
                this.active_value = element.value
            })

            if(this.active_value == ""){
                this.$message.error('活动信息不能为空')
                return false
            }


            this.form.list = JSON.parse(JSON.stringify(this.fieldList));
            this.form.list.forEach((element) => {
                delete element.name;
            });
            // console.log(this.form.list)

            saveBooking(this.form).then((res) => {
                if (res.data.code == 20000) {
                    this.$router.push("/schedule");
                    this.$message.success(res.data.message);
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.reserve {
    width: 100%;
    background-color: #f8f8f8;
    // 标题
    .reserve-title {
        color: #333333;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .reserve-center {
        width: 1200px;
        margin: 20px auto;
        border: 1px solid #f8f8f8;
        background-color: #f8f8f8;
        // 凯迪拉克中心
        .reserve-batten {
            width: 100%;
            height: 320px;
            background-color: #ffffff;
            padding: 20px;
            display: flex;
            .batten-left {
                width: 436px;
                height: 280px;
                border-radius: 4px;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                }
            }
            .batten-right {
                width: 704px;
                margin-left: 20px;
                .batten-title {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h2 {
                        color: #333333;
                        font-size: 20px;
                        font-weight: bold;
                    }
                    div {
                        display: flex;
                        align-items: center;
                        p {
                            color: #999999;
                            font-size: 16px;
                        }
                        span {
                            color: #333333;
                            font-size: 18px;
                            font-weight: bold;
                        }
                    }
                }
                .batten-address {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 10px;
                    div {
                        display: flex;
                        align-items: center;
                        p {
                            color: #bbbbbb;
                            font-size: 14px;
                            margin-left: 15px;
                        }
                    }
                    h3 {
                        color: #123e90;
                        font-size: 14px;
                    }
                }
                .batten-map {
                    width: 100%;
                    height: 210px;
                    margin-top: 15px;
                }
            }
        }
        // 活动基本概况
        .reserve-survey {
            width: 100%;
            background-color: #ffffff;
            margin-top: 15px;
            padding: 20px;
            .survey-center {
                display: flex;
                justify-content: space-between;
                .survey-list-cen {
                    width: 45%;
                    .survey-list {
                        width: 100%;
                        margin-bottom: 15px;
                        p {
                            color: #333333;
                            font-size: 12px;
                            padding-bottom: 6px;
                            i {
                                color: #ff7100;
                                font-size: 12px;
                            }
                        }
                        /deep/ .el-input__inner {
                            height: 32px;
                            line-height: 23px;
                            width: 100%;
                        }
                        .survey-list-date {
                            display: flex;
                            align-items: center;
                            margin-top: 10px;
                            flex-wrap: wrap;
                            max-height: 100px;
                            overflow: auto;
                            div {
                                padding: 0 12px;
                                height: 24px;
                                background-color: #f2f2f2;
                                border-radius: 16px;
                                line-height: 24px;
                                margin-right: 15px;
                                position: relative;
                                margin-bottom: 8px;
                                margin-top: 3px;
                                p {
                                    color: #666666;
                                    font-size: 14px;
                                }
                                i {
                                    width: 12px;
                                    height: 12px;
                                    background-color: #fa6400;
                                    border-radius: 50%;
                                    color: #ffffff;
                                    font-size: 12px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    position: absolute;
                                    right: -2px;
                                    top: -3px;
                                    cursor: pointer;
                                }
                                button {
                                    border: none;
                                    color: #666666;
                                    font-size: 14px;
                                }
                            }
                            /deep/ .el-input__inner {
                                background-color: #ffffff !important;
                            }
                        }
                        .survey-list-radio {
                            display: flex;
                            align-items: center;
                            margin-bottom: 10px;
                            p {
                                margin-right: 10px;
                                padding-bottom: 0;
                            }
                        }
                        .upload-enda{
                            width:140px;
                            height: 96px;
                            cursor: pointer;
                        }
                        .upload-end {
                            display: flex;
                            align-items: flex-end;
                            cursor: pointer;
                            
                            .avatar-uploader {
                                width: 140px;
                                height: 96px;
                                background-color: #f2f2f2;
                                border-radius: 4px;
                                border: 1px dashed #d8d8d8;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .upload-end-submit {
                                margin-left: 10px;
                                color: #333333;
                                font-size: 12px;
                                span {
                                    color: #d8d8d8;
                                    font-size: 12px;
                                }
                            }
                            img {
                                width: 140px;
                                height: 96px;
                                border-radius: 4px;
                            }
                        }
                        .sponsor-center {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 15px;
                            /deep/ .el-input {
                                width: 98%;
                                margin-right: 10px;
                            }
                            /deep/ .el-select {
                                margin-right: 8px;
                            }
                            /deep/ .el-input__icon {
                                line-height: 25px;
                            }
                        }
                        .survey-list-icon {
                            /deep/ .el-date-editor .el-range__icon {
                                display: none;
                            }
                            /deep/ .el-range-editor .el-range-input {
                                display: none;
                            }
                            /deep/ .el-input__inner {
                                height: 24px;
                                background-color: #f2f2f2;
                                border-radius: 12px;
                                color: #666666;
                                font-size: 14px;
                                border: none;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                            }
                        }
                        /deep/ .el-select__caret {
                            line-height: 20px;
                        }
                    }
                }
                /deep/ .el-select {
                    width: 100%;
                }
            }
            /deep/ .el-input__suffix{
                line-height:32px;
            }
        }
        //主办方信息
        .sponsor {
            height: 378px;
        }
        //承办方信息
        .undertaker {
            height: 235px;
        }
        //活动信息
        .reserve-information {
            width: 100%;
            height: 478px;
            background-color: #ffffff;
            margin-top: 15px;
            padding: 20px;
        }
        .reserve-bottom {
            width: 100%;
            height: 36px;
            margin: 50px 0 100px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                width: 142px;
                height: 36px;
                background: linear-gradient(270deg, #ff6100 0%, #ffb800 100%);
                border-radius: 6px;
                border: none;
                color: #ffffff;
                font-size: 14px;
            }
        }
    }
    /deep/ .el-upload-dragger {
        width: 100px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .avater-tips {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            color: #999999;
            font-size: 12px;
            padding-top: 8px;
        }
        img {
            width: 15px !important;
            height: 15px !important;
            border-radius: 0 !important;
        }
    }
    .list-time {
        font-size: 14px;
        color: red;
        margin-left: 5px;
        display: block;
    }
}
</style>